import { Terminal } from "../models/terminal.model";

export class LoadTerminals {
  public static readonly type = "[Terminals] load terminals";
  constructor(
    public filters?: any
  ) {}
}

export class AddTerminal {
  public static readonly type = "[Terminals] add terminal";
  constructor(
    public payload: Terminal,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class UpdateTerminal {
  public static readonly type = "[Terminals] update terminal";
  constructor(
    public payload: Terminal,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class RemoveTerminal {
  public static readonly type = "[Terminals] remove terminal";
  constructor(
    public terminalId: string,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}
